import './About.css';
import Pic from '../PicOfMe.JPG';

function About() {
    return (<div className="about">
        <div>
            <h1>Samuel Armstrong</h1>
            <div className='cont'>
                <img src={Pic} alt="Me" />
                <p>I am seeking a Software Engineer position where I can make a difference to "the industry"
                    and to my employer, leveraging my analytical problem-solving skills paired with strong project
                    management.
                    I am a software developer with a background in Java, Python, and Web Development, with extensive experience in many Linux distributions (Ubuntu, Debian, etc.).
                    I have experience with cybersecurity techniques, encryption, decryption, and digital forensics from my time as a student.
                    In my time as an Intern for both Intel and Hewlett-Packard Enterprise, I worked with mostly Python, also some Java, C++, C#, and some JSON as well.
                    I honed my skills as a corporate-based developer, learning how to work with members of other teams within the company to maximize my time and abilities.
                    I also worked with Docker and Kubernetes extensively.
                    I also have much experience in the world of web development, most notably JavaScript, React.js, Spring and Spring Boot, PHP, HTML, SQL, CSS, and .NET.
                </p>
            </div>
        </div>
        <div>
            <h1>Education:</h1>
            <ul>
                <strong>University of Texas at Dallas.<br />
                    Bachelor's degree in Computer Science
                </strong>
                <ul>
                    <p>Member of CS^2 Computing Scholars Honors Program</p>
                    <p>Minor in Statistics</p>
                    <p>Certificate of Information Assurance</p>
                </ul>
            </ul>
        </div>
        <div>
            <h1>Significant Awards:</h1>
            <ul>
                <p>Eagle Scout Award</p>
                <p>National Honor Medal for Lifesaving</p>
                <p>High School Competitive Coding Team State Qualifier 2018 2019</p>
                <p>HP Codewars 3rd Place 2019</p>
                <p>High School Computer Applications State Champion 2019</p>
                <p>High School Mobile Application Development Team</p>
            </ul>
        </div>
    </div>);
}

export default About;