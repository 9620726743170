import './Projects.css';
import ProjectItem from './ProjectItem';

function Projects() {
    const exp = [
        {
            title: "Lead Engineer, Full Stack Developer",
            project: "Coder Tools Site",
            start: "July 2023",
            end: "Current",
            desc: `Website with tools for developers to use (base conversion, regex, etc.)
            Designed with Spring Boot and React, possibly Docker later on.`,
            link: ""
        },
        {
            title: "Lead Engineer, Full Stack Developer",
            project: "Clothes Bartering E-Commerce Site",
            start: "August 2023",
            end: "Current",
            desc: `Website where people can not only purchase clothes, but trade items directly for one another. 
            Built with React front end and database on back end. Possible Machine Learning add-ons later.`,
            link: ""
        },
        {
            title: "Project Manager, Lead Engineer",
            project: "A+ Computer Science Practice Site",
            start: "August 2020",
            end: "May 2021",
            desc: `We added the ability to solve existing problems in Python on the site.
            We also updated the PHP of the website, and added PDO functionality to protect from SQL injection.
            In our second phase, we added an online IDE to the site for both Python and Java.
            With this, we included functionality to save and download your work in the IDE.
            Most of the work was done in JavaScript, PHP, and SQL, with some work on HTML and CSS.`,
            link: "https://www.practice.apluscompsci.com/"
        }
    ];

    return (
        <ul className="work-experience">
            {exp.map(experience =>
                <ProjectItem
                    key={experience.title}
                    title={experience.title}
                    project={experience.project}
                    start={experience.start}
                    desc={experience.desc}
                    end={experience.end}
                    link={experience.link}
                />
            )}
        </ul>
    );
}

export default Projects;