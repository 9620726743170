import React from "react";
import Skills from "../components/Skills";

const SkillsPage = () => {
    return (
        <div>
            <Skills />
        </div>
    );
}

export default SkillsPage;