import './Contact.css';
import Card from './UI/Card.js'

function Contact() {
    return (
        <div className="contact">
            <h2>Social Media:</h2>
            <ul>
                <a href="https://www.linkedin.com/in/samuel-armstrong-96007a19a/">My Linkedin</a>
            </ul>
            <ul>
                <a href="https://www.instagram.com/samarmstrong212212/">My Instagram</a>
            </ul>
            <h2>Email:</h2>
            <ul><p>sammy.armstrong7@gmail.com</p></ul>
            <h2>Phone Number:</h2>
            <ul><p>(832) 691-1590</p></ul>
        </div>
    );
}

export default Contact;