import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';
import AboutPage from './pages/about';
import WorkExperiencePage from './pages/workexperience';
import SkillsPage from './pages/skills';
import ProjectsPage from './pages/projects';
import ContactPage from './pages/contact';

function App() {
  return (
    <body>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<AboutPage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/work-experience' element={<WorkExperiencePage />} />
          <Route path='/projects' element={<ProjectsPage />} />
          <Route path='/skills' element={<SkillsPage />} />
        </Routes>
      </Router>
    </body>
  );
}

export default App;