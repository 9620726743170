import React from 'react';
import Card from "./UI/Card";
import './ProjectItem.css';

function ProjectItem(data) {


    if (data.link === "") {
        return (
            <Card className="project-item">
                <div>
                    <h2>{data.title}</h2>
                    <h3>{data.project}</h3>
                    <p>{data.desc}</p>
                    <h3>{data.start} - {data.end}</h3>
                </div>
            </Card>
        );
    }
    else {
        return (
            <Card className="project-item">
                <div>
                    <h2>{data.title}</h2>
                    <h3>{data.project} - <a href={data.link}>Link</a></h3>
                    <p>{data.desc}</p>
                    <h3>{data.start} - {data.end}</h3>
                </div>
            </Card>
        );
    }
}

export default ProjectItem;