import React, { useState, useEffect } from "react";
import './index.css';
import { Nav, NavLink, NavMenu }
    from "./NavbarElements";

const Navbar = () => {

    // const [scrolled, setScrolled] = React.useState(false);
    // const handleScroll = () => {
    //     const offset = window.scrollY;
    //     if (offset > 200) {
    //         setScrolled(true);
    //     }
    //     else {
    //         setScrolled(false);
    //     }
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll)
    // })
    // let navbarClasses = ['navbar'];
    // if (scrolled) {
    //     navbarClasses.push('scrolled');
    // }
    // className={navbarClasses.join(" ")}

    return (
        <Nav>
            <NavMenu>
                <NavLink to="/about" activeStyle>
                    About
                </NavLink>
                <NavLink to="/work-experience" activeStyle>
                    Work Experience
                </NavLink>
                <NavLink to="/skills" activeStyle>
                    Skills
                </NavLink>
                <NavLink to="/projects" activeStyle>
                    Projects
                </NavLink>
                <NavLink to="/contact" activeStyle>
                    Contact Me
                </NavLink>
            </NavMenu>
        </Nav>
    );
};

export default Navbar;