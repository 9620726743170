import './Skills.css';

function Skills() {
    const inter = ['iOS', 'Android', 'Cybersecurity', 'Assembly', 'Machine Learning', 'GCP', 'Azure', 'Information Assurance', 'Embedded Systems', 'Computer Networking', 'Vue', '.NET', 'Kubernetes', 'C#'];
    const adv = ['React.js', 'Node.js', 'PHP', 'SQL', 'MySQL', 'PDO', 'Information Assurance', 'AWS', 'Docker', 'Spring Boot', 'Spring', 'Databases', 'Visual Studio', 'Eclipse'];
    const exp = ["Java", "Python", 'C++', 'HTML', 'JSON', 'Linux', 'Unix', 'C', 'JavaScript', 'CSS', "Git", 'Github', 'Object-Oriented Programming', 'Statistics', 'Web Development'];

    return (<div className="skills">
        <div>
            <h3>Intermediate:</h3>
            <ul>{inter.map(skill => <li>{skill}</li>)}</ul>
        </div>
        <div>
            <h3>Advanced:</h3>
            <ul>{adv.map(skill => <li>{skill}</li>)}</ul>
        </div>
        <div>
            <h3>Expert:</h3>
            <ul>{exp.map(skill => <li>{skill}</li>)}</ul>
        </div>
    </div>);
}

export default Skills;