import './WorkExperience.css';
import WorkExperienceItem from './WorkExperienceItem';

function WorkExperience() {
    const exp = [
        {
            title: "Software Developer, Test and Packet Writer/Editor",
            company: "A+ Computer Science",
            start: "May 2023",
            end: "Current",
            location: "Cypress, TX",
            desc: `Design, develop, and test Java and Python code, problems, and packets to prepare promising developers.\n
            Developed and maintain a front-end React web application using JavaScript and Node.js.\n 
            Build and test Java Spring and Spring Boot applications.`
        },
        {
            title: "Test and Packet Writer, Contest Director",
            company: "University Interscholastic League",
            start: "August 2022",
            end: "May 2023",
            location: "Austin, TX",
            desc: "I am involved in the writing and editing of packets of computer science problems, and computer science tests, for the UIL Academic Meets (there are 5 each year)."
        },
        {
            title: "Software Developer, Ethernet Products Manageability Intern",
            company: "Intel Corporation",
            start: "May 2022",
            end: "August 2022",
            location: "Houston, TX",
            desc: "Created a Python-based PLDM package creator that uses JSON-based feeds sent through a backend Ethernet bus. Enhanced validation scripts for MCTP / PLDM Manageability. Developed and implemented embedded systems solutions in Python for classified hardware."
        },
        {
            title: "Software Engineering Intern",
            company: "Hewlett-Packard Enterprise",
            start: "May 2021",
            end: "July 2021",
            location: "Houston, TX",
            desc: "Interned with the HPE Software Engineering team to develop, test, and implement updated Docker images using Java and Python. Performed coding tasks on Docker projects resolving numerous, ongoing company issues."
        },
        {
            title: "Software Developer, Test and Packet Writer/Editor",
            company: "A+ Computer Science",
            start: "August 2015",
            end: "May 2021",
            location: "Cypress, TX",
            desc: "Application development lead for a team tasked with regular PHP and MySQL database updates and maintenance for an advanced Java / Python coding problem website. Designed and developed a Linux hosted cloud-based IDE that compiles and runs Java and Python code. Design, develop, and test Java and Python code, problems, and packets to prepare promising developers"
        }
    ];

    return (
        <ul className="work-experience">
            {exp.map(experience =>
                <WorkExperienceItem
                    key={experience.title}
                    title={experience.title}
                    company={experience.company}
                    start={experience.start}
                    location={experience.location}
                    desc={experience.desc}
                    end={experience.end}
                />
            )}
        </ul>
    );
}

export default WorkExperience;