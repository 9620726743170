import React from 'react';
import Card from "./UI/Card";
import './WorkExperienceItem.css';

function WorkExperienceItem(data) {

    return (
        <Card className="workexperience-item">
            <div>
                <h2>{data.title}</h2>
                <h3>{data.company} - {data.location}</h3>
                <p>{data.desc}</p>
                <h3>{data.start} - {data.end}</h3>
            </div>
        </Card>
    );
}

export default WorkExperienceItem;